<template>
	<div class="container-fluid">
		<div class="row p-4">
			<div class="col-4 col-lg-2 mb-2">
				<label class="form-lable">標題</label>
			</div>
			<div class="col-8 col-lg-4 mb-2">
				<!-- <Multiselect track-by="title" label="title" v-model="searchInfo.title" placeholder="Please select"
					:options="dropDownOfferingList" :searchable="true" :allow-empty="true">
				</Multiselect> -->
				<input v-model="searchInfo.title" class="form-control" placeholder="Please enter" />
			</div>
			<div class="col-12 col-lg-6  ">
				<button class="search-btn" @click="ChangePage(1, 'search')">
					搜尋
				</button>
			</div>
		</div>
		<div class="row p-4">
			<div class="col-12  mb-3" style="display: flex;justify-content: flex-end;">
				<button class="upload-btn" @click="popForm_show('create', {})">
					<font-awesome-icon :icon="['fas', 'plus']" class="mx-1" />
					新增服務項目
				</button>
			</div>
		</div>
		<div class="row p-4 py-0">
			<div class="col-12 ">
				<div v-if="searchOfferingList">
					<!-- {{ searchOfferingList.list }} -->
				</div>
				<table class="table table-bordered">
					<thead>
						<tr>
							<th style="width: 160px;">圖片</th>
							<th>標題</th>
							<th>簡易敘述</th>
							<th>建立時間</th>
							<th>修改時間</th>
							<th style="width:65px;">順序</th>
							<th style="width:80px;">啟用</th>
							<th style="width:80px;"> </th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-if="!searchOfferingList || !searchOfferingList.list || searchOfferingList.list.length == 0">
							<td colspan="9" class="td_noData">Nodata</td>
						</tr>
						<template
							v-if="searchOfferingList && searchOfferingList.list && searchOfferingList.list.length != 0">
							<tr v-for="(item, index) in searchOfferingList.list" :key="index">
								<td>
									<span v-if="item.image">
										<img v-bind:src="'data:image/jpeg;base64,' + item.image" style="width: 100%;">
									</span>
								</td>
								<td>
									<span v-if="item.title" v-bind:hovertext="item.title"></span>{{ item.title }}
								</td>
								<td>
									<span v-if="item.desc" v-bind:hovertext="item.desc"></span>{{ item.desc }}
								</td>
								<td>
									<span v-if="item.created_at" v-bind:hovertext="item.created_at"></span>{{
					item.created_at }}
								</td>
								<td>
									<span v-if="item.updated_at" v-bind:hovertext="item.updated_at"></span>{{
					item.updated_at }}
								</td>
								<td>
									<span v-if="item.order" v-bind:hovertext="item.order"></span>{{
					item.order }}
								</td>
								<td>
									<span v-if="item.is_show" style="color: #4CAF50;">
										啟用
									</span>
									<span v-if="!item.is_show" style="color: #ed626a;">
										未啟用
									</span>

								</td>
								<td class="">
									<img src="../../assets/img/edit.png" class="list-icon"
										@click="popForm_show('edit', item)">
									<img src="../../assets/img/delete.png" class="list-icon"
										@click="popForm_show('delete', item)">
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<div class="col-12">
				<PaginationVue v-bind:page-info="cm_pageInfo" v-bind:change-event="ChangePage"></PaginationVue>
			</div>
		</div>
	</div>
	<div class="pop-panel " v-if="popForm.show">
		<div class="pop-panel-container " v-bind:class="{ 'xl-panel': !(popForm.mode == 'delete') }">
			<div class="pop-panel-title" v-if="popForm.mode == 'upload'">上傳</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'delete'">刪除</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'edit'">編輯</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'create'">新增</div>
			<span class="xmark" @click="popForm_clean()">
				✕
			</span>
			<div class="pop-panel-inner">
				<div class="row" v-if="popForm.mode == 'delete' || popForm.mode == 'download'">
					<div class="col-12 pop-panel-text" v-if="popForm.mode == 'delete'">
						確定要刪除文章嗎？
					</div>
					<div class="pop-panel-btn">
						<button class="btn btn-outline-primary" @click="popForm_clean()">No</button>
						<button class="btn btn-primary " @click="popForm_save">Yes</button>
					</div>
				</div>
				<div class="row" v-if="popForm.mode != 'delete' && popForm.mode != 'download'">
					<div class="row">
						<div class="col-3 mb-3">
							<label class="form-lable require">圖片</label>
						</div>
						<div class="col-9 mb-3" v-if="popForm.mode == 'create' || popForm.mode == 'edit'">
							<button class="addFile-btn" onclick="document.getElementById('getFile').click()">
								<font-awesome-icon :icon="['fas', 'plus']" class="me-2" />
								選擇檔案
							</button>
							<div style="visibility: hidden;height: 0;">
								<input type="file" id="getFile" accept="image/*" @change="fileChange"
									:key="popForm.formData">
							</div>
							<div class=" mt-3 fileInfoGroup">
								<span v-if="popForm.formData_info" class="file-info">
									<img src="../../assets/img/file.png"> {{ popForm.formData_info.name }} (
									{{ Math.round(popForm.formData_info.size / 1000000 * 100) / 100 }}MB)
								</span>
								<img v-if="popForm.formData_info" :src="previewObjectURL()" class="filePreview" alt="">
								<!-- <div class="upload_btn" v-if="popForm.formData_info"></div> -->
								<div class="errMsg"
									v-if="popForm.formData_info && popForm.formData_info.size / 1000000 * 100 / 100 > file_max">
									檔案超過2MB無法上傳
								</div>
							</div>
						</div>
						<div class="col-3 mb-3">
							<label class="form-lable require">標題</label>
						</div>
						<div class="col-9 mb-3">
							<input v-model="popForm.form.title" class="form-control" placeholder="Please enter" />
						</div>
						<div class="col-3 mb-3">
							<label class="form-lable require">簡易敘述</label>
						</div>
						<div class="col-9 mb-3">
							<textarea v-model="popForm.form.desc" class="form-control" placeholder="Please enter"
								rows="3"></textarea>
						</div>
						<div class="col-3 mb-3">
							<label class="form-lable require">順序</label>
						</div>
						<div class="col-9 mb-3">
							<input v-model="popForm.form.order" class="form-control" placeholder="Please enter"
								type="number" />
						</div>
						<div class="col-3 mb-3">
							<label class="form-lable  ">啟用</label>
						</div>
						<div class="col-9 mb-3">
							<div class="input-group input-check-group">
								<div class="form-check">
									<input class="form-check-input" type="checkbox" id="is_show" value="is_show"
										v-model="popForm.form.is_show">
									<label class="form-check-label" for="is_show"> 啟用</label>
								</div>
							</div>
						</div>
						<div class="col-3 mb-3">
							<label class="form-lable require">項目</label>
						</div>
						<div class="col-9 mb-3">
							<div v-for="(item, index) in popForm.form.item" v-bind:key="index"
								class="inputArrGroup mb-1">
								<input v-model="popForm.form.item[index].name" class="form-control "
									placeholder="Please enter" />
								<button v-if="popForm.form.item.length != 1" class="btn  btn-delete "
									@click="deleteInputArr('item', index)">×</button>
								<button v-if="index == popForm.form.item.length - 1" class="btn btn-add"
									@click="addInputArr('item', index)">﹢</button>
							</div>
						</div>
						<div class="col-3 mb-3">
							<label class="form-lable require">QA</label>
						</div>
						<div class="col-9 mb-3">
							<div v-for="(item, index) in popForm.form.question" v-bind:key="index"
								class="textareaArrGroup mb-1">
								<div>
									<span>Q {{ index + 1 }}：</span>
									<textarea v-model="popForm.form.question[index].content" class="form-control mb-1"
										placeholder="Please enter question" rows="3"></textarea>
									<span>A {{ index + 1 }}：</span>
									<textarea v-model="popForm.form.question[index].answer" class="form-control"
										placeholder="Please enter answer" rows="3"></textarea>
								</div>


								<button v-if="popForm.form.question.length != 1" class="btn  btn-delete "
									@click="deleteInputArr('question', index)">×</button>
								<button v-if="index == popForm.form.question.length - 1" class="btn btn-add"
									@click="addInputArr('question', index)">﹢</button>
							</div>
						</div>
					</div>
					<div class="errMsg">{{ popForm.msg }}</div>
					<div class="cpp_btn">
						<button class="btn btn-outline-primary" @click="popForm_clean()">Cancel</button>
						<button class="btn btn-primary " @click="popForm_save">Save</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import 'datatables.net-select-bs5'
import PaginationVue from '../../components/layout/Pagination.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
	searchUserList: {},
	name: 'OfferingManagement',
	components: {
		PaginationVue,
	},
	computed: {
		...mapState(
			{
				dropDownOfferingList: state => state.offering.dropDown,
				searchOfferingList: state => state.offering.search,
				createOffering: state => state.offering.create,
				deleteOffering: state => state.offering.delete,
				updateOffering: state => state.offering.update,
			}
		),
		...mapGetters(['CheckPermissions'])
	},
	data() {
		return {
			file_max: 2,
			tableModule: {
				searchHistory: null,
				searchCache: null,
				sort_order: "desc",
				sort_field: "id"
			},
			searchInfo: {
				name: null,
				checkedType: [],
				dateStart: null,
				dateEnd: null,
			},
			areaId: "",
			cm_pageInfo: {
				totalPage: 0,
				nowPage: 0,
			},
			popForm: {
				mode: "",
				show: false,
				isStart: false,
				isStarted: false,
				timer: null,
				itemData: {},
				formData: new FormData(),
				formData_info: null,
				form: {
					title: null,
					desc: null,
					is_show: ['is_show'],
					order: 0
				},
				msg: ""
			}
		};
	},
	mounted() {
		this.ChangePage(1)
		// this.GetDropDownOffering({
		// 	"page": 1,
		// 	"count": 100,
		// 	"sort_field": "id",
		// 	"sort_order": "desc",
		// 	"is_output": 0
		// })
	},
	watch: {
		searchOfferingList: function (val) {
			this.cm_pageInfo = {
				totalPage: val.total_page,
				nowPage: val.page,
			}
			this.tableModule.searchHistory = this.tableModule.searchCache;
		},
		createOffering(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.GetSearchOffering({
					"page": 1,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0
				})
				// this.GetDropDownOffering({
				// 	"page": 1,
				// 	"count": 100,
				// 	"sort_field": "id",
				// 	"sort_order": "desc",
				// 	"is_output": 0
				// })
			} else {
				this.popForm.msg = val.data.header.message;
				console.log(val.data)
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		},
		updateOffering(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.GetSearchOffering({
					"page": 1,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0
				})
				// this.GetDropDownOffering({
				// 	"page": 1,
				// 	"count": 100,
				// 	"sort_field": "id",
				// 	"sort_order": "desc",
				// 	"is_output": 0
				// })
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		},
		deleteOffering(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.ChangePage(1);
				// this.GetDropDownOffering({
				// 	"page": 1,
				// 	"count": 100,
				// 	"sort_field": "id",
				// 	"sort_order": "desc",
				// 	"is_output": 0
				// })
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		}
	},
	methods: {
		...mapActions([
			'GetDropDownOffering',
			'GetSearchOffering',
			'GetCreateOffering',
			'GetDeleteOffering',
			'GetUpdateOffering'
		]),
		popForm_show(_mode, _item) {
			this.popForm_clean()
			this.popForm.show = true;
			this.popForm.mode = _mode;
			this.popForm.itemData = _item;
			if (this.popForm.mode == "edit") {
				this.popForm.formData = new FormData();
				var file = this.dataURLtoFile('data:image/jpeg;base64,' + _item.image, "已上傳檔案");
				this.popForm.formData.append("image", file);
				console.log('file', file)
				this.popForm.formData_info = file;
				this.popForm.form = {
					title: _item.title,
					id: _item.id,
					desc: _item.desc,
					is_show: _item.is_show ? ['is_show'] : [],
					order: _item.order,
					question: [
						{
							"content": "",
							"answer": "",
							"order": 0
						}
					],
					item: [
						{
							"name": '',
							"order": 0
						}
					]
				}
				if (_item.questions.length != -1) this.popForm.form.question = [];
				_item.questions.forEach(element => {
					this.popForm.form.question.push({ content: element.content, answer: element.answer, order: element.order });
				});
				if (_item.items.length != -1) this.popForm.form.item = [];
				_item.items.forEach(element => {
					this.popForm.form.item.push({ name: element.name, order: element.order });
				});
			}
		},
		popForm_save() {
			this.popForm.msg = "";
			if (this.popForm.mode == "delete") {
				this.GetDeleteOffering({
					"id": this.popForm.itemData.id
				});
				return;
			}
			if (!this.popForm.formData_info) {
				this.popForm.msg = "請上傳圖片";
				return;
			}
			if (!this.popForm.form.title) {
				this.popForm.msg = "標題 為必填欄位";
				return;
			}
			if (!this.popForm.form.desc) {
				this.popForm.msg = "簡易敘述 為必填欄位";
				return;
			}
			if (!this.popForm.form.order && this.popForm.form.order != 0) {
				this.popForm.msg = "順序 為必填欄位";
				return;
			}

			var needReturn = 0;
			/* item */
			if (!this.popForm.form.item) {
				this.popForm.msg = "服務項目 不可為空";
				return;
			}
			var _item = [];
			this.popForm.form.item.forEach((element, index) => {
				_item.push({
					"name": element.name,
					"order": index
				})
				if (element.name == '') {
					this.popForm.msg = "服務項目 不可為空";
					needReturn = 1;
					return;
				}
			});
			/* question */
			if (!this.popForm.form.question) {
				this.popForm.msg = "證書 不可為空";
				return;
			}
			var _question = [];
			this.popForm.form.question.forEach((element, index) => {
				_question.push({
					"content": element.content,
					"answer": element.answer,
					"order": index
				})
				if (element.content == '') {
					this.popForm.msg = "question 不可為空";
					needReturn = 1;
					return;
				}
				if (element.answer == '') {
					this.popForm.msg = "answer 不可為空";
					needReturn = 1;
					return;
				}
			});
			if (needReturn) return;
			// var payload;
			// payload = {
			// 	"title": this.popForm.form.title,
			// 	"desc": this.popForm.form.desc,
			// 	"content": this.popForm.form.content,
			// 	"is_show": this.popForm.form.is_show.indexOf('is_show') != -1 ? 1 : 0,
			// 	"order": this.popForm.form.order
			// };
			this.popForm.formData.append("title", this.popForm.form.title)
			this.popForm.formData.append("desc", this.popForm.form.desc)
			this.popForm.formData.append("order", this.popForm.form.order)
			this.popForm.formData.append("is_show", this.popForm.form.is_show.indexOf('is_show') != -1 ? 1 : 0)
			this.popForm.formData.append("question", JSON.stringify(_question))
			this.popForm.formData.append("item", JSON.stringify(_item))

			if (this.popForm.mode == "create") {
				this.GetCreateOffering(this.popForm.formData);
				return;
			}
			if (this.popForm.mode == "edit") {
				// payload.id = this.popForm.itemData.id;
				this.popForm.formData.append("id", this.popForm.form.id)
				console.log(this.popForm.form.id)
				//塞資料  
				this.GetUpdateOffering(this.popForm.formData);
				return;
			}
		},
		popForm_clean() {
			this.popForm = {
				mode: "",
				show: false,
				isStart: false,
				isStarted: false,
				timer: null,
				itemData: {},
				formData: new FormData(),
				form: {
					title: null,
					desc: null,
					is_show: ['is_show'],
					order: 0,
					question: [
						{
							"content": "",
							"answer": "",
							"order": 0
						}
					],
					item: [
						{
							"name": '',
							"order": 0
						}
					]
				},
				msg: ""
			}
		},
		ChangePage(num, mode) {
			var payload = {};
			if (mode == 'search' || !this.tableModule.searchHistory) {
				payload = {
					"page": num,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0,
					"search": {
					}
				}
				if (this.searchInfo.title) payload.search.title = [this.searchInfo.title];
				this.tableModule.searchCache = payload;
			} else {
				this.tableModule.searchCache = this.tableModule.searchHistory;
				this.tableModule.searchCache.page = num;
				this.tableModule.searchCache.sort_field = this.tableModule.sort_field
				this.tableModule.searchCache.sort_order = this.tableModule.sort_order
			}
			this.tableModule.searchCache.is_output = mode == 'download' ? 1 : 0;
			this.GetSearchOffering(this.tableModule.searchCache);
		},
		copyEvent(id) {
			var str = document.getElementById(id + '_link');
			window.getSelection().selectAllChildren(str);
			document.execCommand("Copy")
		},
		// updateTiny(val) {
		// 	console.log('updateTiny')
		// 	this.popForm.form.content = val;
		// },
		dataURLtoFile(dataurl, filename) {
			var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, { type: mime });
		},
		fileChange(e) {
			this.popForm.formData = new FormData();
			this.popForm.formData.append('image', e.target.files[0]); //放進上傳的檔案
			this.popForm.formData_info = e.target.files[0];
			this.popForm.msg = null;

			if ((e.target.files[0].size / 1000000 * 100 / 100) >= this.file_max) {
				this.popForm.msg = '檔案超過100MB無法上傳';
				return
			}
		},
		previewObjectURL() {
			var objectURL = window.URL.createObjectURL(this.popForm.formData_info);
			return objectURL;
		},
		addInputArr(key) {
			if (this.popForm.form[key].length > 10) return;
			var _obj = {};
			switch (key) {
				case 'item':
					_obj = { name: '', order: '' }
					break;
				case 'question':
					_obj = { answer: '', content: '', order: '' }
					break;
			}
			this.popForm.form[key].push(_obj)
		},
		deleteInputArr(key, index) {
			if (this.popForm.form[key].length <= 1) return;
			this.popForm.form[key].splice(index, 1);
		}
	}
}
</script>