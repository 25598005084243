 
<template>
  <!-- {{ props.tinymceDoc }} -->
  <vue3-tinymce v-model="state.value" :setting="state.setting" @change="updateTiny" />
</template>

<script setup>
import { reactive, defineProps, defineEmits } from 'vue';
import Vue3Tinymce from '@jsdawn/vue3-tinymce';

const props = defineProps(['tinymceDoc'])
const emit = defineEmits(["update-tiny"]);   //定义一个变量来接收父组件传来的方法
const updateTiny = () => {
  emit("update-tiny", state.value)
}

const state = reactive({
  props: ['tinymceDoc'],
  value: props.tinymceDoc,
  content: 'hello vue3-tinymce!',
  // editor 配置项
  setting: {
    height: 600,
    toolbar:
      "code undo redo | fullscreen | blocks alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontsize forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
    toolbar_mode: "sliding",
    quickbars_selection_toolbar:
      "removeformat | bold italic underline strikethrough | fontsize forecolor backcolor",
    plugins: " code link image media table lists fullscreen  ",
    font_size_formats: "12px 14px 16px 18px",
    link_default_target: "_blank",
    link_title: false,
    nonbreaking_force_tab: true,
    // 以中文简体为例
    language: "zh-Hant",
    language_url:
      "/zh-Hant.js"
  },
});

</script>  