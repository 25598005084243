<template> 
 <div> 
 </div>  
</template>
<script>   
  
export default {
  name: 'Home',
  components: {  
  },
  computed:{ 
	}
	,
  data () {
    return {   
		msg:''
	}
  },
  mounted(){ 
  }, 
  methods:{   
  },  
}
</script>
 