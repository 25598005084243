<template>
	<div class="container ">
		<form class="col-sm-12 offset-md-3 col-md-6 offset-lg-4 col-lg-4 login-vue">
			<img class="login-logo" src="../../assets/head-logo.svg">
			<!-- <form class="row "> -->
			<div class="input-group mb-4 mt-5">
				<span class="input-group-text igt-before" id="">
					<!-- <font-awesome-icon :icon="['fas', 'user']" /> -->
					<img src="../../assets/img/member.png" class="login-icon-member">
				</span>
				<input v-model="email" type="text" class="form-control" placeholder="Enter Employee Email">
			</div>
			<div class="input-group mb-2 input-group-psw">
				<span class="input-group-text igt-before" id="">
					<img src="../../assets/img/psw.png" class="login-icon-psw">
					<img src="../../assets/img/show.png" class="login-icon-show" :class="{ 'hide': showPsw }"
						@click="showPsw = true">
					<img src="../../assets/img/unshow.png" class="login-icon-unshow" :class="{ 'hide': !showPsw }"
						@click="showPsw = false">
				</span>
				<input v-model="psw" v-bind:type="type_showPsw()" class="form-control" placeholder="Enter Password">
			</div>
			<div class="input-group mb-3 ">
				<div class="form-check ms-3">
					<input v-model="saveToken" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
					<label class="form-check-label" for="flexCheckDefault">
						Remember Password
					</label>
				</div>
			</div>

			<div class="errMsg">{{ errorMsg }} </div>
			<div class="input-group mb-2 mt-2">
				<button @click="Login($event) && recaptcha(email, psw, saveToken)" class="btn btn-primary w-100 ">LOG
					IN</button>
			</div>
			<!-- <div class="input-group mb-5 justify-content-center ">
				<button class="mb-5 login-forgot" >Forgot Password?</button>  
			</div>  -->
			<!-- </form>  -->
		</form>
	</div>
</template>
 
<script>
// import axios from 'axios';  
import { mapState } from 'vuex'
import { useReCaptcha } from 'vue-recaptcha-v3'
// import axios from 'axios';
// import router from '../../router'
import store from '../../store'
export default {
	setup() {
		const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

		// submit 回傳一組 token，並把 token 傳給後端驗證 
		const recaptcha = async (email, psw, saveToken) => {
			await recaptchaLoaded();
			const token = await executeRecaptcha('login');
			console.log('token token' + token);
			// createConsultation(form);
			store.dispatch('GetLoginToken',
				{
					"email": email,
					"g-recaptcha-response": token,
					"password": psw,
					"saveToken": saveToken
				}
			);
		}
		return {
			recaptcha,
		}
	},
	name: 'Login',
	computed: {
		...mapState(
			{
				loginInfo: state => state.loginInfo,
			}
		),
	},
	watch: {
		loginInfo(val) {
			this.errorMsg = "";
			if (val.header.message != "Success") {
				this.errorMsg = val.header.message;
			} {
				this.errorMsg = val.header.message;
				for (var i in val.data) {
					this.errorMsg = val.data[i][0];
				}
			}
		}
	},
	data() {
		return {
			showPsw: false,
			email: "",
			psw: "",
			saveToken: false,
			errorMsg: ""
		}
	},
	mounted() {
		var userInfo = JSON.parse(localStorage.getItem('userInfo'))

		if (userInfo) {
			this.$store.state.userInfo = userInfo;
			this.psw = userInfo.password;
			this.email = userInfo.email;
		}
	},
	methods: {
		type_showPsw() {
			return this.showPsw ? 'text' : 'password';
		},
		Login(e) {
			e.preventDefault();
			if (this.email == "") {
				this.errorMsg = "請輸入信箱";
				return;
			}
			if (this.psw == "") {
				this.errorMsg = "請輸入密碼";
				return;
			}
			return true
		}
	}
}
</script>
