import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' 
import axios from 'axios';
import VueAxios from 'vue-axios';
import { VueReCaptcha } from 'vue-recaptcha-v3'

import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap"   
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
 
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import "./assets/style/main.css"
import "./assets/style/layout.css"
import "./assets/style/global.css"
// import Multiselect from '@vueform/multiselect'
 
import Multiselect from 'vue-multiselect'
library.add(fas)
const app = createApp(App);
app
.use(VueAxios, axios)
.use(VueReCaptcha, { siteKey: '6LcAhuYoAAAAAHNcjeoLHaEyxuJ-We390YG_4wZl' })
.use(store) 
.use(router)
.provide('$axios', axios)
.component('font-awesome-icon', FontAwesomeIcon) 
.component('Multiselect', Multiselect)
.component('VueDatePicker', VueDatePicker)
.mount('#app')

app.config.globalProperties.$axios = axios;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = '/web';
