<template>
	<div class="container-fluid">
		<div class="row p-4" v-if="CheckPermissions('list content')">
			<div class="col-4 col-lg-2 mb-2">
				<label class="form-lable">檔案名稱</label>
			</div>
			<div class="col-8 col-lg-4 mb-2">
				<!-- <Multiselect track-by="file_name" label="file_name" v-model="searchInfo.name" placeholder="Please select"
					:options="dropDownContentList" :searchable="true" :allow-empty="true">
				</Multiselect> -->
				<input v-model="searchInfo.name" class="form-control" placeholder="Please enter" />
			</div>
			<!-- <div class="col-4 col-lg-1 mb-2">
				<label class="form-lable">Date</label>
			</div>
			<div class="col-8 col-lg-5 mb-2" style="
        display: flex;
        gap: 7px;
        align-items: center;
      ">
				<VueDatePicker v-model="searchInfo.dateStart" placeholder="Please select" type="date" :format="format">
				</VueDatePicker>
				―
				<VueDatePicker v-model="searchInfo.dateEnd" placeholder="Please select" type="date" :format="format">
				</VueDatePicker>

			</div> -->
			<!-- <div class="col-4 col-lg-2 mb-2">
				<label class="form-lable">File Type</label>
			</div>
			<div class="col-8 col-lg-10 mb-2">
				<div class="input-group input-check-group ">
					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="search_PDF" value="PDF"
							v-model="searchInfo.checkedType">
						<label class="form-check-label" for="search_PDF"> PDF </label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="search_PPT" value="PPT"
							v-model="searchInfo.checkedType">
						<label class="form-check-label" for="search_PPT"> PPT</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="search_DOC" value="DOC"
							v-model="searchInfo.checkedType">
						<label class="form-check-label" for="search_DOC"> DOC</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="search_XLSX" value="XLSX"
							v-model="searchInfo.checkedType">
						<label class="form-check-label" for="search_XLSX"> XLSX</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="search_Video" value="Video"
							v-model="searchInfo.checkedType">
						<label class="form-check-label" for="search_Video"> Video</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="search_Image" value="Image"
							v-model="searchInfo.checkedType">
						<label class="form-check-label" for="search_Image"> Image</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="search_TXT" value="TXT"
							v-model="searchInfo.checkedType">
						<label class="form-check-label" for="search_TXT"> TXT</label>
					</div>
				</div>
			</div> -->
			<div class="col-12 col-lg-6  ">
				<button class="search-btn" @click="cm_ChangePage(1, 'search')">
					搜尋
				</button>
			</div>
		</div>
		<div class="row p-4">
			<div class="col-12  mb-3" style="display: flex;justify-content: flex-end;">
				<button class="upload-btn" @click="cm_show('upload', {})" v-if="CheckPermissions('import content')">
					<img src="../../assets/img/upload.png" class="icon-upload">
					檔案上傳
				</button>
			</div>
		</div>
		<div class="row p-4 py-0" v-if="CheckPermissions('list content')">
			<div class="col-12 ">
				<table class="table table-bordered">
					<thead>
						<tr>
							<th style="width: 120px;">預覽</th>
							<th>檔案名稱</th>
							<th style="width: 70px;">連結</th>
							<th>備註</th>
							<th style="width: 120px;">檔案大小</th>
							<th style="width: 120px;">上傳人員</th>
							<!-- <th>Type</th> -->
							<th style="width: 90px;">副檔名</th>
							<!-- <th>Start Date</th>
							<th>End Date</th> -->
							<th style="width: 120px;"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="!searchContentList || !searchContentList.list || searchContentList.list.length == 0">
							<td colspan="9" class="td_noData">Nodata</td>
						</tr>
						<template v-if="searchContentList && searchContentList.list && searchContentList.list.length != 0">
							<tr v-for="(item, index) in searchContentList.list" :key="index">
								<td>
									<img v-bind:src="$store.state.DOMAIN_URL + '/storage/' + item.file_hyperlink"
										style="width: 100%;padding: 3%;">
								</td>
								<td>
									<span v-if="item.file_name" v-bind:hovertext="item.file_name"></span>{{ item.file_name
									}}
								</td>
								<td>
									<span class="pinHover"
										v-if="CheckPermissions('export content') && (new Date()) <= new Date(item.date_end)"
										v-bind:hovertext='$store.state.DOMAIN_URL + "/storage/" + item.file_hyperlink'></span>
									<img v-if="CheckPermissions('export content') && (new Date()) <= new Date(item.date_end)"
										src="../../assets/img/pin.png" class="list-icon" @click="copyEvent(item.id)">
									<div v-bind:id="item.id + '_link'" style="font-size: 0;height: 0;width: 0;">
										{{ $store.state.DOMAIN_URL + "/storage/" + item.file_hyperlink }}
									</div>
								</td>
								<td>
									<span v-if="item.file_desc" v-bind:hovertext="item.file_desc"></span>
									{{ item.file_desc }}
								</td>
								<td>{{ Math.round(item.file_size / 1000000 * 100) / 100 }}MB</td>
								<td>{{ item.uploader_user ? item.uploader_user.name : "" }}</td>
								<td>{{ item.file_extension }}
								</td>
								<!-- <td>
									<span v-if="item.content_file_type"
										v-bind:hovertext="item.content_file_type ? item.content_file_type.name : ''"></span>
									{{ item.content_file_type ? item.content_file_type.name : "" }}
								</td> -->
								<!-- <td>{{ item.date_from }}</td>
								<td>{{ item.date_end }}</td> -->
								<td class="">
									<img v-if="CheckPermissions('update content')" src="../../assets/img/edit.png"
										class="list-icon" @click="cm_show('edit', item)">
									<img v-if="CheckPermissions('delete content')" src="../../assets/img/delete.png"
										class="list-icon" @click="cm_show('delete', item)">
									<img v-if="CheckPermissions('export content') && (new Date()) <= new Date(item.date_end)"
										src="../../assets/img/download.png" class="list-icon"
										@click="cm_show('download', item)">
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<div class="col-12">
				<PaginationVue v-bind:page-info="cm_pageInfo" v-bind:change-event="cm_ChangePage"></PaginationVue>
			</div>
		</div>
	</div>
	<div class="pop-panel" v-if="cm_form.show">
		<div class="pop-panel-container ">
			<div class="pop-panel-title" v-if="cm_form.mode == 'upload'">上傳檔案</div>
			<div class="pop-panel-title" v-if="cm_form.mode == 'download'">下載檔案</div>
			<div class="pop-panel-title" v-if="cm_form.mode == 'delete'">刪除檔案</div>
			<div class="pop-panel-title" v-if="cm_form.mode == 'edit'">編輯檔案</div>
			<span class="xmark" @click="cm_clean()">
				✕
			</span>
			<div class="pop-panel-inner">
				<div class="row" v-if="cm_form.mode == 'delete' || cm_form.mode == 'download'">
					<div class="col-12 pop-panel-text" v-if="cm_form.mode == 'delete'">
						請問要刪除檔案嗎?
					</div>
					<div class="col-12 pop-panel-text" v-if="cm_form.mode == 'download'">
						Do you allow to download the file?
					</div>
					<div class="pop-panel-btn">
						<button class="btn btn-outline-primary" @click="cm_clean()">No</button>
						<button class="btn btn-primary " @click="cm_save">Yes</button>

					</div>
				</div>
				<div class="row" v-if="cm_form.mode != 'delete' && cm_form.mode != 'download'">
					<div class="col-12" v-if="cm_form.mode == 'upload'">
						<button class="addFile-btn" onclick="document.getElementById('getFile').click()">
							<font-awesome-icon :icon="['fas', 'plus']" class="me-2" />
							Add File
						</button>
					</div>
					<div v-if="cm_form.mode == 'upload'" class="col-12 " style="visibility: hidden;height: 0;">
						<input type="file" id="getFile" @change="fileChange" :key="cm_form.formData">
					</div>
					<div v-if="cm_form.mode == 'upload'" class="col-12 my-4" style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;">
						<span v-if="cm_form.formData_info" class="file-info">
							<img src="../../assets/img/file.png"> {{ cm_form.formData_info.name }} (
							{{ Math.round(cm_form.formData_info.size / 1000000 * 100) / 100 }}MB)
						</span>
						<div class="upload_btn" v-if="cm_form.formData_info">
							<!-- <div class="upload_bar"
								v-if="cm_form.formData_info && !cm_form.isStarted && cm_form.formData_info.size / 1000000 * 100 / 100 <= file_max">
								<div class="upload_bar_inner" :class="{ 'w100per': cm_form.isStart }"></div>
							</div>
							<div class="upload_start"
								v-if="cm_form.formData_info && !cm_form.isStarted && cm_form.formData_info.size / 1000000 * 100 / 100 <= file_max"
								@click="upload_start_click()"></div>
							<div class="upload_cancel" v-if="cm_form.formData_info && !cm_form.isStarted"
								@click="upload_cancel_click()"></div>
							<div class="upload_delete" v-if="cm_form.formData_info && cm_form.isStarted"
								@click="upload_cancel_click()">
							</div> -->
						</div>
						<!-- <div class="errMsg"
							v-if="cm_form.formData_info && cm_form.formData_info.size / 1000000 * 100 / 100 > file_max">
							檔案超過100MB無法上傳
						</div> -->
					</div>
					<div class="row">
						<div class="col-3 mb-3">
							<label class="form-lable require">檔案名稱</label>
						</div>
						<div class="col-9 mb-3">
							<input v-model="cm_form.form.name" class="form-control" placeholder="Please enter" />
						</div>
						<div class="col-3 mb-3">
							<label class="form-lable require">備註</label>
						</div>
						<div class="col-9 mb-3">
							<textarea v-model="cm_form.form.description" class="form-control" placeholder="Please enter"
								rows="4" maxlength="20"></textarea>
						</div>
						<!-- <div class="col-3 mb-3">
							<label class="form-lable require">Document Type</label>
						</div>
						<div class="col-9 mb-3">
							<Multiselect v-model="cm_form.form.document_type" track-by="name" label="name"
								placeholder="Please select" :options="dropDownContentFileType" :searchable="true"
								:allow-empty="false">
							</Multiselect>
						</div> -->
						<!-- <div class="col-3 mb-3">
							<label class="form-lable require">Date</label>
						</div>
						<div class="col-9 mb-3" style="
                display: flex;
                gap: 7px;
                align-items: center;
              ">

							<VueDatePicker v-model="cm_form.form.dateStart" placeholder="Please select" :format="format">
							</VueDatePicker>
							―
							<VueDatePicker v-model="cm_form.form.dateEnd" placeholder="Please select" :format="format">
							</VueDatePicker>
						</div> -->
					</div>
					<div class="errMsg">{{ cm_form.msg }}</div>
					<div class="cpp_btn">
						<button class="btn btn-outline-primary" @click="cm_clean()">Cancel</button>
						<button class="btn btn-primary " @click="cm_save">Save</button>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import 'datatables.net-select-bs5';
import PaginationVue from '../../components/layout/Pagination.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { ref } from 'vue'
export default {
	searchUserList: {},
	name: 'contentManagement',
	components: {
		PaginationVue,
	},
	computed: {
		...mapState(
			{
				dropDownContentList: state => state.content.dropDown,
				searchContentList: state => state.content.search,
				createContent: state => state.content.create,
				deleteContent: state => state.content.delete,
				updateContent: state => state.content.update,
			}
		),
		...mapGetters(['CheckPermissions'])
	},
	data() {
		const format = (date) => {
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			return `${year}/${month}/${day}`;
		}
		return {
			file_max: 2,
			tableModule: {
				searchHistory: null,
				searchCache: null,
				sort_order: "desc",
				sort_field: "id"
			},
			searchInfo: {
				name: null,
				checkedType: [],
				dateStart: null,
				dateEnd: null,
			},
			format,
			areaId: "",
			cm_pageInfo: {
				totalPage: 1,
				nowPage: 1,
			},
			cm_form: {
				mode: "",
				show: false,
				formData: new FormData(),
				formData_info: null,
				isStart: false,
				isStarted: false,
				timer: null,
				itemData: {},
				form: {
					name: null,
					description: null,
					document_type: null,
					dateStart: ref(new Date()),
					dateEnd: ref(new Date()),
				},
				msg: ""
			}
		};
	},
	mounted() {
		// this.GetDropDownContentFileTypeList({
		// 	"page": 1,
		// 	"count": 10,
		// 	"sort_field": "id",
		// 	"sort_order": "desc",
		// 	"is_output": 0
		// })
		this.cm_ChangePage(1)
		// this.GetDropDownContent({
		// 	"page": 1,
		// 	"count": 100,
		// 	"sort_field": "id",
		// 	"sort_order": "desc",
		// 	"is_output": 0
		// })
		// this.cm_show('download')
	},
	watch: {
		searchContentList: function (val) {
			this.cm_pageInfo = {
				totalPage: val.total_page,
				nowPage: val.page,
			}
			this.tableModule.searchHistory = this.tableModule.searchCache;
		},
		createContent(val) {
			if (val.data.header.message == "Success") {
				this.cm_clean();
				this.GetSearchContent({
					"page": 1,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0
				})
				// this.GetDropDownContent({
				// 	"page": 1,
				// 	"count": 100,
				// 	"sort_field": "id",
				// 	"sort_order": "desc",
				// 	"is_output": 0
				// })
			} else {
				this.cm_form.msg = val.data.header.message;
				console.log(val.data)
				for (var i in val.data.data) {
					this.cm_form.msg = val.data.data[i][0];
				}
			}
		},
		updateContent(val) {
			if (val.data.header.message == "Success") {
				this.cm_clean();
				this.GetSearchContent({
					"page": 1,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0
				})
				// this.GetDropDownContent({
				// 	"page": 1,
				// 	"count": 100,
				// 	"sort_field": "id",
				// 	"sort_order": "desc",
				// 	"is_output": 0
				// })
			} else {
				this.cm_form.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.cm_form.msg = val.data.data[i][0];
				}
			}
		},
		deleteContent(val) {
			if (val.data.header.message == "Success") {
				this.cm_clean();
				this.cm_ChangePage(1);
				// this.GetDropDownContent({
				// 	"page": 1,
				// 	"count": 100,
				// 	"sort_field": "id",
				// 	"sort_order": "desc",
				// 	"is_output": 0
				// })
			} else {
				this.cm_form.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.cm_form.msg = val.data.data[i][0];
				}
			}
		}
	},
	methods: {
		...mapActions([
			// 'GetDropDownContentFileTypeList',
			'GetDropDownContent',
			'GetSearchContent',
			'GetCreateContent',
			'GetDeleteContent',
			'GetUpdateContent'
		]),
		cm_show(_mode, _item) {
			this.cm_form.show = true;
			this.cm_form.mode = _mode;
			this.cm_form.itemData = _item;

			if (this.cm_form.mode == "edit") {
				this.cm_form.form = {
					name: _item.file_name,
					description: _item.file_desc,
					document_type: 1,//this.getContentFileTypeItem(_item.content_file_type.id),
					dateStart: ref(new Date(_item.date_from)),
					dateEnd: ref(new Date(_item.date_end)),
				}
			}
		},
		cm_save() {
			this.cm_form.msg = "";
			if (this.cm_form.mode == "delete") {
				this.GetDeleteContent({
					"id": this.cm_form.itemData.id
				});
				return;
			}
			if (this.cm_form.mode == "download") {
				//https://abbott-frontend.maryreina.com/storage/content_file_MDzFhoa76e.png
				window.open(this.$store.state.DOMAIN_URL + '/storage/' + this.cm_form.itemData.file_hyperlink);
				//https://abbott-frontend.maryreina.com/storage/content_file_6RTCC6LfLU.xlsx
				// var img_url = 'storage/content_file_6RTCC6LfLU.xlsx';

				// this.$axios({
				// 	url: img_url, // File URL Goes Here
				// 	method: 'GET',
				// 	responseType: 'blob',
				// }).then((res) => { 
				// 	var FILE = window.URL.createObjectURL(new Blob([res.data]));
				// 	var docUrl = document.createElement('x');
				// 	docUrl.href = FILE;
				// 	docUrl.setAttribute('download', 'file.pdf');
				// 	document.body.appendChild(docUrl);
				// 	docUrl.click();

				// });

				this.cm_clean()
				// let a = document.createElement("a");
				// a.href = 'https://abbott-frontend.maryreina.com/storage/content_file_MDzFhoa76e.png';
				// a.download = 'filename';
				// a.click();
				// let url = 'https://abbott-frontend.maryreina.com/storage/content_file_MDzFhoa76e.png';
				// let image = new Image();
				// image.setAttribute("crossOrigin", "anonymous");
				// image.crossOrigin = "anonymous";
				// image.src = url;
				// image.onload = () => {
				// 	let canvas = document.createElement("canvas");
				// 	canvas.width = image.width;
				// 	canvas.height = image.height;
				// 	let ctx = canvas.getContext("2d");
				// 	ctx.drawImage(image, 0, 0, image.width, image.height);
				// 	canvas.toBlob(blob => {
				// 		let url = URL.createObjectURL(blob);
				// 		let a = document.createElement("a");
				// 		a.download = 'name';
				// 		a.href = url;
				// 		a.click();
				// 		a.remove();
				// 		// 用完释放URL对象
				// 		URL.revokeObjectURL(url);
				// 	});
				// };
				return;
			}
			if (!this.cm_form.form.name) {
				this.cm_form.msg = "檔案名稱 為必填欄位";
				return;
			}
			if (!this.cm_form.form.description) {
				this.cm_form.msg = "備註  為必填欄位";
				return;
			}
			// if (!this.cm_form.form.document_type) {
			// 	this.cm_form.msg = "Document Type  為必填欄位";
			// 	return;
			// }
			// if (this.cm_form.form.dateStart >= this.cm_form.form.dateEnd) {
			// 	this.cm_form.msg = "起迄日期區間錯誤";
			// 	return;
			// }

			if (this.cm_form.mode == "upload") {

				if (!this.cm_form.formData_info) {
					// this.uploadPanel.msg = 'please select the flie';
					this.cm_form.msg = '請選擇檔案';
					return;
				}
				if (!this.cm_form.formData_info.size / 1000000 * 100 / 100 >= this.file_max) {
					this.cm_form.msg = '檔案超過2MB無法上傳';
					return;
				}
				// if (!this.cm_form.isStarted) {
				// 	// this.cm_form.msg = 'please start the flie';
				// 	this.cm_form.msg = '請先上傳檔案';
				// 	return;
				// }
				//塞資料
				this.cm_form.formData.append("file_hyperlink", 1);
				this.cm_form.formData.append("file_name", this.cm_form.form.name);
				this.cm_form.formData.append("file_desc", this.cm_form.form.description);
				this.cm_form.formData.append("date_from", '2023-01-01');
				this.cm_form.formData.append("date_end", '2123-01-01');
				this.cm_form.formData.append("content_file_type_id", 1);//this.cm_form.form.document_type.id
				this.GetCreateContent(this.cm_form.formData);
				return;
			}
			if (this.cm_form.mode == "edit") {
				var payload = {
					"id": this.cm_form.itemData.id,
					"content_file_type_id": 1,//this.cm_form.form.document_type.id,
					"file_desc": this.cm_form.form.description,
					// "date_from": this.cm_form.form.dateStart.toISOString().slice(0, 10),
					// "date_end": this.cm_form.form.dateEnd.toISOString().slice(0, 10)
					"file_name": this.cm_form.form.name,
					"date_from": '2023-01-01',
					"date_end": '2123-01-01',
					"file_hyperlink": 1,
				};
				//塞資料  
				this.GetUpdateContent(payload);
				return;
			}
		},
		upload_cancel_click() {
			this.cm_form.formData = new FormData();
			this.cm_form.formData_info = null;
			this.cm_form.msg = null;
			this.cm_form.isStart = false;
			this.cm_form.isStarted = false;
			clearTimeout(this.cm_form.timer)
		},
		upload_start_click() {
			if (this.cm_form.isStart == true) return;
			this.cm_form.isStart = true
			this.cm_form.timer = setTimeout(() => {
				this.cm_form.isStarted = true;
			}, 5500);
		},
		cm_clean() {
			this.cm_form = {
				mode: "",
				show: false,
				formData: new FormData(),
				formData_info: null,
				isStart: false,
				isStarted: false,
				timer: null,
				itemData: {},
				form: {
					document_type: null,
					name: null,
					description: null,
					dateStart: ref(new Date()),
					dateEnd: ref(new Date()),
				},
				msg: ""
			}
		},
		fileChange(e) {
			this.cm_form.formData = new FormData();
			this.cm_form.formData.append('file', e.target.files[0]); //放進上傳的檔案
			this.cm_form.formData_info = e.target.files[0];
			this.cm_form.msg = null;

			if ((e.target.files[0].size / 1000000 * 100 / 100) >= this.file_max) {
				this.cm_form.msg = '檔案超過2MB無法上傳';
				return
			}
		},
		cm_ChangePage(num, mode) {
			var payload = {};
			if (mode == 'search' || !this.tableModule.searchHistory) {
				var _ckType = this.searchInfo.checkedType;
				if (_ckType.indexOf('Image') != -1) {
					_ckType = _ckType.concat(["jpg", "jpeg", "png", "webp"]);
				}
				if (_ckType.indexOf('XLSX') != -1) {
					_ckType = _ckType.concat(["xls"]);
				}
				if (_ckType.indexOf('Video') != -1) {
					_ckType = _ckType.concat(["mp4", "mov"]);
				}
				if (_ckType.indexOf('PPT') != -1) {
					_ckType = _ckType.concat(["pptx"]);
				}
				if (_ckType.indexOf('DOC') != -1) {
					_ckType = _ckType.concat(["docx"]);
				}
				payload = {
					"page": num,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0,
					"search": {
						// "file_extension": _ckType,
					}
				}
				if (this.searchInfo.name) payload.search.file_name = [this.searchInfo.name];
				if (this.searchInfo.dateStart) payload.search.date_from = this.searchInfo.dateStart.toISOString().slice(0, 10);
				if (this.searchInfo.dateEnd) payload.search.date_end = this.searchInfo.dateEnd.toISOString().slice(0, 10);
				this.tableModule.searchCache = payload;
			} else {
				this.tableModule.searchCache = this.tableModule.searchHistory;
				this.tableModule.searchCache.page = num;
				this.tableModule.searchCache.sort_field = this.tableModule.sort_field
				this.tableModule.searchCache.sort_order = this.tableModule.sort_order
			}
			this.tableModule.searchCache.is_output = mode == 'download' ? 1 : 0;
			this.GetSearchContent(this.tableModule.searchCache);
		},
		copyEvent(id) {
			var str = document.getElementById(id + '_link');
			window.getSelection().selectAllChildren(str);
			document.execCommand("Copy")
		}
	}
}
</script>
 