<template>
	<div class="container-fluid">
		<div class="row p-4">
			<div class="col-4 col-lg-2 mb-2">
				<label class="form-lable">標題</label>
			</div>
			<div class="col-8 col-lg-4 mb-2">
				<!-- <Multiselect track-by="title" label="title" v-model="searchInfo.title" placeholder="Please select"
					:options="dropDownArticleList" :searchable="true" :allow-empty="true">
				</Multiselect> -->
				<input v-model="searchInfo.title" class="form-control" placeholder="Please enter" />
			</div>
			<div class="col-4 col-lg-2 mb-2">
				<label class="form-lable">文章類型</label>
			</div>
			<div class="col-8 col-lg-4 mb-2">
				<Multiselect track-by="name" label="name" v-model="searchInfo.article_type_id"
					placeholder="Please select" :options="dropDownArticleTypeList" :searchable="true"
					:allow-empty="true">
				</Multiselect>
			</div>
			<div class="col-12 mt-3">
				<button class="search-btn" @click="ChangePage(1, 'search')">
					搜尋
				</button>
			</div>
		</div>
		<div class="row p-4">
			<div class="col-12  mb-3" style="display: flex;justify-content: flex-end;">
				<button class="upload-btn" @click="popForm_show('create', {})">
					<font-awesome-icon :icon="['fas', 'plus']" class="mx-1" />
					新增文章
				</button>
			</div>
		</div>
		<div class="row p-4 py-0">
			<div class="col-12 ">
				<table class="table table-bordered">
					<thead>
						<tr>
							<th>文章類型</th>
							<th>標題</th>
							<th>簡易敘述</th>
							<th>建立時間</th>
							<th>修改時間</th>
							<th>順序</th>
							<th>啟用</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="!searchArticleList || !searchArticleList.list || searchArticleList.list.length == 0">
							<td colspan="9" class="td_noData">Nodata</td>
						</tr>
						<template
							v-if="searchArticleList && searchArticleList.list && searchArticleList.list.length != 0">
							<tr v-for="(item, index) in searchArticleList.list" :key="index">
								<td>
									<span v-if="item.article_type && item.article_type.name"
										v-bind:hovertext="item.article_type.name"></span>{{ item.article_type.name }}
								</td>
								<td>
									<span v-if="item.title" v-bind:hovertext="item.title"></span>{{ item.title }}
								</td>
								<td>
									<span v-if="item.desc" v-bind:hovertext="item.desc"></span>{{ item.desc }}
								</td>
								<td>
									<span v-if="item.created_at" v-bind:hovertext="item.created_at"></span>{{
					item.created_at }}
								</td>
								<td>
									<span v-if="item.updated_at" v-bind:hovertext="item.updated_at"></span>{{
					item.updated_at }}
								</td>
								<td>
									<span v-if="item.order" v-bind:hovertext="item.order"></span>{{
					item.order }}
								</td>
								<td>
									<span v-if="item.is_show" style="color: #4CAF50;">
										啟用
									</span>
									<span v-if="!item.is_show" style="color: #ed626a;">
										未啟用
									</span>

								</td>
								<td class="">
									<img src="../../assets/img/edit.png" class="list-icon"
										@click="popForm_show('edit', item)">
									<img src="../../assets/img/delete.png" class="list-icon"
										@click="popForm_show('delete', item)">
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<div class="col-12">
				<PaginationVue v-bind:page-info="cm_pageInfo" v-bind:change-event="ChangePage"></PaginationVue>
			</div>
		</div>
	</div>
	<div class="pop-panel " v-if="popForm.show">
		<div class="pop-panel-container " v-bind:class="{ 'xl-panel': !(popForm.mode == 'delete') }">
			<div class="pop-panel-title" v-if="popForm.mode == 'upload'">上傳</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'delete'">刪除</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'edit'">編輯</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'create'">新增</div>
			<span class="xmark" @click="popForm_clean()">
				✕
			</span>
			<div class="pop-panel-inner">
				<div class="row" v-if="popForm.mode == 'delete' || popForm.mode == 'download'">
					<div class="col-12 pop-panel-text" v-if="popForm.mode == 'delete'">
						確定要刪除文章嗎？
					</div>
					<div class="pop-panel-btn">
						<button class="btn btn-outline-primary" @click="popForm_clean()">No</button>
						<button class="btn btn-primary " @click="popForm_save">Yes</button>
					</div>
				</div>
				<div class="row" v-if="popForm.mode != 'delete' && popForm.mode != 'download'">
					<div class="row">
						<div class="col-3 mb-3">
							<label class="form-lable require">文章類型</label>
						</div>
						<div class="col-9 mb-3">
							<Multiselect track-by="name" label="name" v-model="popForm.form.article_type"
								placeholder="Please select" :options="dropDownArticleTypeList" :searchable="true"
								:allow-empty="true">
							</Multiselect>
						</div>
						<div class="col-3 mb-3">
							<label class="form-lable require">標題</label>
						</div>
						<div class="col-9 mb-3">
							<input v-model="popForm.form.title" class="form-control" placeholder="Please enter" />
						</div>
						<div class="col-3 mb-3">
							<label class="form-lable require">簡易敘述</label>
						</div>
						<div class="col-9 mb-3">
							<textarea v-model="popForm.form.desc" class="form-control" placeholder="Please enter"
								rows="3"></textarea>
						</div>
						<div class="col-3 mb-3">
							<label class="form-lable require">順序</label>
						</div>
						<div class="col-9 mb-3">
							<input v-model="popForm.form.order" class="form-control" placeholder="Please enter"
								type="number" />
						</div>
						<div class="col-3 mb-3">
							<label class="form-lable  ">啟用</label>
						</div>
						<div class="col-9 mb-3">
							<div class="input-group input-check-group">
								<div class="form-check">
									<input class="form-check-input" type="checkbox" id="is_show" value="is_show"
										v-model="popForm.form.is_show">
									<label class="form-check-label" for="is_show"> 啟用</label>
								</div>
							</div>
						</div>
						<div class="col-3 mb-3">
							<label class="form-lable require">文章內容</label>
						</div>
						<div class="col-9 mb-3">
							<TinyMCE v-bind:tinymce-doc="popForm.form.content" @update-tiny="updateTiny"></TinyMCE>
							<!-- <TinyMCE v-bind:tinymce-doc="popForm.tinymce_doc" @update-tiny="updateTiny"></TinyMCE> -->
						</div>
					</div>
					<div class="errMsg">{{ popForm.msg }}</div>
					<div class="cpp_btn">
						<button class="btn btn-outline-primary" @click="popForm_clean()">Cancel</button>
						<button class="btn btn-primary " @click="popForm_save">Save</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import 'datatables.net-select-bs5'
import PaginationVue from '../../components/layout/Pagination.vue'
import TinyMCE from '../../components/tools/TinyMCE.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
	searchUserList: {},
	name: 'ArticleManagement',
	components: {
		PaginationVue,
		TinyMCE
	},
	computed: {
		...mapState(
			{
				dropDownArticleTypeList: state => state.articleType.dropDown,
				// dropDownArticleList: state => state.article.dropDown,
				searchArticleList: state => state.article.search,
				createArticle: state => state.article.create,
				deleteArticle: state => state.article.delete,
				updateArticle: state => state.article.update,
			}
		),
		...mapGetters(['CheckPermissions'])
	},
	data() {
		const format = (date) => {
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			return `${year}/${month}/${day}`;
		}
		return {
			file_max: 100,
			tableModule: {
				searchHistory: null,
				searchCache: null,
				sort_order: "desc",
				sort_field: "order"
			},
			searchInfo: {
				name: null,
				checkedType: [],
				dateStart: null,
				dateEnd: null,
			},
			format,
			areaId: "",
			cm_pageInfo: {
				totalPage: 0,
				nowPage: 0,
			},
			popForm: {
				mode: "",
				show: false,
				isStart: false,
				isStarted: false,
				timer: null,
				itemData: {},
				form: {
					article_type: null,
					title: null,
					desc: null,
					content: null,
					is_show: ['is_show'],
					order: 0
				},
				msg: ""
			}
		};
	},
	mounted() {
		this.ChangePage(1)
		// this.GetDropDownArticle({
		// 	"page": 1,
		// 	"count": 100,
		// 	"sort_field": "id",
		// 	"sort_order": "desc",
		// 	"is_output": 0
		// })
		this.GetDropDownArticleType({
			"page": 1,
			"count": 100,
			"sort_field": "id",
			"sort_order": "desc",
			"is_output": 0
		})
		// this.popForm_show('download')
	},
	watch: {
		searchArticleList: function (val) {
			this.cm_pageInfo = {
				totalPage: val.total_page,
				nowPage: val.page,
			}
			this.tableModule.searchHistory = this.tableModule.searchCache;
		},
		createArticle(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.GetSearchArticle({
					"page": 1,
					"count": 10,
					"sort_field": "order",
					"sort_order": "desc",
					"is_output": 0
				})
				// this.GetDropDownArticle({
				// 	"page": 1,
				// 	"count": 100,
				// 	"sort_field": "id",
				// 	"sort_order": "desc",
				// 	"is_output": 0
				// })
			} else {
				this.popForm.msg = val.data.header.message;
				console.log(val.data)
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		},
		updateArticle(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.GetSearchArticle({
					"page": 1,
					"count": 10,
					"sort_field": "order",
					"sort_order": "desc",
					"is_output": 0
				})
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		},
		deleteArticle(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.ChangePage(1);
				// this.GetDropDownArticle({
				// 	"page": 1,
				// 	"count": 100,
				// 	"sort_field": "id",
				// 	"sort_order": "desc",
				// 	"is_output": 0
				// })
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		}
	},
	methods: {
		...mapActions([
			'GetDropDownArticleType',
			'GetDropDownArticle',
			'GetSearchArticle',
			'GetCreateArticle',
			'GetDeleteArticle',
			'GetUpdateArticle'
		]),
		popForm_show(_mode, _item) {
			this.popForm.show = true;
			this.popForm.mode = _mode;
			this.popForm.itemData = _item;
			if (this.popForm.mode == "edit") {
				this.popForm.form = {
					article_type: this.getArticleTypeItem(_item.article_type_id),
					title: _item.title,
					desc: _item.desc,
					content: _item.content,
					is_show: _item.is_show ? ['is_show'] : [],
					order: _item.order
				}
			}
		},
		popForm_save() {
			this.popForm.msg = "";
			if (this.popForm.mode == "delete") {
				this.GetDeleteArticle({
					"id": this.popForm.itemData.id
				});
				return;
			}

			if (!this.popForm.form.article_type) {
				this.popForm.msg = "文章類型 為必填欄位";
				return;
			}
			if (!this.popForm.form.title) {
				this.popForm.msg = "標題 為必填欄位";
				return;
			}
			if (!this.popForm.form.desc) {
				this.popForm.msg = "簡易敘述 為必填欄位";
				return;
			}
			if (!this.popForm.form.order && this.popForm.form.order != 0) {
				this.popForm.msg = "順序 為必填欄位";
				return;
			}
			if (!this.popForm.form.content) {
				this.popForm.msg = "文章內容 為必填欄位";
				return;
			}

			var payload;
			payload = {
				"article_type_id": this.popForm.form.article_type.id,
				"title": this.popForm.form.title,
				"desc": this.popForm.form.desc,
				"content": this.popForm.form.content,
				"is_show": this.popForm.form.is_show.indexOf('is_show') != -1 ? 1 : 0,
				"order": this.popForm.form.order
			};
			if (this.popForm.mode == "create") {
				this.GetCreateArticle(payload);
				return;
			}
			if (this.popForm.mode == "edit") {
				payload.id = this.popForm.itemData.id;
				//塞資料  
				this.GetUpdateArticle(payload);
				return;
			}
		},
		popForm_clean() {
			this.popForm = {
				mode: "",
				show: false,
				isStart: false,
				isStarted: false,
				timer: null,
				itemData: {},
				form: {
					article_type: null,
					title: null,
					desc: null,
					content: null,
					is_show: ['is_show'],
					order: 0
				},
				msg: ""
			}
		},
		ChangePage(num, mode) {
			var payload = {};
			if (mode == 'search' || !this.tableModule.searchHistory) {
				payload = {
					"page": num,
					"count": 10,
					"sort_field": "order",
					"sort_order": "desc",
					"is_output": 0,
					"search": {
					}
				}
				if (this.searchInfo.title) payload.search.title = [this.searchInfo.title];
				if (this.searchInfo.article_type_id) payload.search.article_type_id = [this.searchInfo.article_type_id.id];
				this.tableModule.searchCache = payload;
			} else {
				this.tableModule.searchCache = this.tableModule.searchHistory;
				this.tableModule.searchCache.page = num;
				this.tableModule.searchCache.sort_field = this.tableModule.sort_field
				this.tableModule.searchCache.sort_order = this.tableModule.sort_order
			}
			this.tableModule.searchCache.is_output = mode == 'download' ? 1 : 0;
			this.GetSearchArticle(this.tableModule.searchCache);
		},
		copyEvent(id) {
			var str = document.getElementById(id + '_link');
			window.getSelection().selectAllChildren(str);
			document.execCommand("Copy")
		},
		updateTiny(val) {
			console.log('updateTiny')
			this.popForm.form.content = val;
		},
		getArticleTypeItem(id) {
			for (var i in this.dropDownArticleTypeList) {
				if (this.dropDownArticleTypeList[i].id == id) {
					return this.dropDownArticleTypeList[i]
				}
			} return null
		},
	}
}
</script>