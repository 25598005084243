<template>
	<div class="container-fluid">
		<div class="row p-4 py-0 my-5" v-if="searchLawyerList && searchLawyerList.list">
			<!-- <div v-if="searchLawyerList && searchLawyerList.list">
				{{ searchLawyerList.list[0]
				}}
				{{ this.popForm.itemData }}
			</div> -->
			<div class="col-3 mb-3">
				<label class="form-lable require">上方形象照 1</label>
			</div>
			<div class="col-9 mb-3">
				<button class="addFile-btn" onclick="document.getElementById('getFile1').click()">
					<font-awesome-icon :icon="['fas', 'plus']" class="me-2" />
					選擇檔案
				</button>
				<div style="visibility: hidden;height: 0;">
					<input type="file" id="getFile1" accept="image/*" @change="fileChange($event, 'photo1')"
						:key="popForm.form.photo1">
				</div>
				<div class=" mt-3 fileInfoGroup">
					<span v-if="popForm.form.photo1" class="file-info">
						<img src="../../assets/img/file.png"> {{ popForm.form.photo1.name }} (
						{{ Math.round(popForm.form.photo1.size / 1000000 * 100) / 100 }}MB)
					</span>
					<img v-if="popForm.form.photo1" :src="previewObjectURL($event, 'photo1')" class="filePreview"
						alt="">
					<div class="errMsg"
						v-if="popForm.form.photo1 && popForm.form.photo1.size / 1000000 * 100 / 100 > file_max">
						檔案超過2MB無法上傳
					</div>
				</div>
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">上方形象照 2</label>
			</div>
			<div class="col-9 mb-3">
				<button class="addFile-btn" onclick="document.getElementById('getFile2').click()">
					<font-awesome-icon :icon="['fas', 'plus']" class="me-2" />
					選擇檔案
				</button>
				<div style="visibility: hidden;height: 0;">
					<input type="file" id="getFile2" accept="image/*" @change="fileChange($event, 'photo2')"
						:key="popForm.form.photo2">
				</div>
				<div class=" mt-3 fileInfoGroup">
					<span v-if="popForm.form.photo2" class="file-info">
						<img src="../../assets/img/file.png"> {{ popForm.form.photo2.name }} (
						{{ Math.round(popForm.form.photo2.size / 1000000 * 100) / 100 }}MB)
					</span>
					<img v-if="popForm.form.photo2" :src="previewObjectURL($event, 'photo2')" class="filePreview"
						alt="">
					<div class="errMsg"
						v-if="popForm.form.photo2 && popForm.form.photo2.size / 1000000 * 100 / 100 > file_max">
						檔案超過2MB無法上傳
					</div>
				</div>
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">下方形象照</label>
			</div>
			<div class="col-9 mb-3">
				<button class="addFile-btn" onclick="document.getElementById('getFile3').click()">
					<font-awesome-icon :icon="['fas', 'plus']" class="me-2" />
					選擇檔案
				</button>
				<div style="visibility: hidden;height: 0;">
					<input type="file" id="getFile3" accept="image/*" @change="fileChange($event, 'photo3')"
						:key="popForm.form.photo3">
				</div>
				<div class=" mt-3 fileInfoGroup">
					<span v-if="popForm.form.photo3" class="file-info">
						<img src="../../assets/img/file.png"> {{ popForm.form.photo3.name }} (
						{{ Math.round(popForm.form.photo3.size / 1000000 * 100) / 100 }}MB)
					</span>
					<img v-if="popForm.form.photo3" :src="previewObjectURL($event, 'photo3')" class="filePreview"
						alt="">
					<div class="errMsg"
						v-if="popForm.form.photo3 && popForm.form.photo3.size / 1000000 * 100 / 100 > file_max">
						檔案超過2MB無法上傳
					</div>
				</div>
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">子標題</label>
			</div>
			<div class="col-9 mb-3">
				<input v-model="popForm.form.title" class="form-control" placeholder="Please enter" />
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">子內文</label>
			</div>
			<div class="col-9 mb-3">
				<textarea v-model="popForm.form.content" class="form-control" placeholder="Please enter"
					rows="3"></textarea>
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">專長</label>
			</div>
			<div class="col-9 mb-3">
				<textarea v-model="popForm.form.skill" class="form-control" placeholder="Please enter"
					rows="4"></textarea>
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">經歷</label>
			</div>
			<div class="col-9 mb-3">
				<div v-for="(item, index) in popForm.form.experience" v-bind:key="index" class="inputArrGroup mb-1">
					<input v-model="popForm.form.experience[index].name" class="form-control "
						placeholder="Please enter" />
					<button v-if="popForm.form.experience.length != 1" class="btn  btn-delete "
						@click="deleteInputArr('experience', index)">×</button>
					<button v-if="index == popForm.form.experience.length - 1" class="btn btn-add"
						@click="addInputArr('experience', index)">﹢</button>
				</div>
			</div>
			<div class="col-3 mb-3" v-if="popForm.form.credential">
				<label class="form-lable require">證書</label>
			</div>
			<div class="col-9 mb-3" v-if="popForm.form.credential">
				<div v-for="(item, index) in popForm.form.credential" v-bind:key="index" class="inputArrGroup mb-1">
					<input v-model="popForm.form.credential[index].name" class="form-control "
						placeholder="Please enter" />
					<button v-if="popForm.form.credential.length != 1" class="btn  btn-delete "
						@click="deleteInputArr('credential', index)">×</button>
					<button v-if="index == popForm.form.credential.length - 1" class="btn btn-add"
						@click="addInputArr('credential', index)">﹢</button>
				</div>
			</div>

			<div class="errMsg">{{ popForm.msg }}</div>
			<div class="cpp_btn">
				<button class="btn btn-outline-primary"
					@click="popForm_show('edit', searchLawyerList.list[0])">Cancel</button>
				<button class="btn btn-primary " @click="popForm_save">Save</button>
			</div>
		</div>
	</div>
</template>

<script>
const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => {
		resolve(reader.result.split(',')[1]);
	}
	reader.onerror = error => reject(error);
});
import { mapState, mapActions } from 'vuex'
export default {
	searchUserList: {},
	name: 'AboutManagement',
	components: {
	},
	computed: {
		...mapState(
			{
				searchLawyerList: state => state.lawyer.search,
				updateLawyer: state => state.lawyer.update,
			}
		),
	},
	data() {
		const format = (date) => {
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			return `${year}/${month}/${day}`;
		}
		return {
			format,
			file_max: 2,
			credential: [
				{
					"name": "credential1",
					"order": 0
				},
				{
					"name": "credential2",
					"order": 0
				}
			],
			tableModule: {
				searchHistory: null,
				searchCache: null,
				sort_order: "desc",
				sort_field: "id"
			},
			searchInfo: {
				name: null,
				checkedType: [],
				dateStart: null,
				dateEnd: null,
			},
			areaId: "",
			cm_pageInfo: {
				totalPage: 0,
				nowPage: 0,
			},
			popForm: {
				mode: "",
				show: false,
				itemData: {},
				formData: new FormData(),
				// formData_info: null,
				form: {
					ckeckAgain: null,
					tank: null,
					times: 1,
					measurement_type: null,
					date: null,
					image: null,
					banner: null,
				},
				msg: ""
			}
		};
	},
	mounted() {
		this.ChangePage(1)

	},
	watch: {
		searchLawyerList: function (val) {
			this.cm_pageInfo = {
				totalPage: val.total_page,
				nowPage: val.page,
			}
			this.tableModule.searchHistory = this.tableModule.searchCache;
			if (val && val.list)
				this.popForm_show('edit', val.list[0])
		},
		updateLawyer: function (val) {
			if (val.data.header.message == "Success") {
				this.GetSearchLawyer({
					"page": 1,
					"count": 1,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0,
				})
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		}
	},
	methods: {
		...mapActions([
			'GetSearchLawyer',
			'GetUpdateLawyer'
		]),
		popForm_show(_mode, _item) {
			this.popForm.show = true;
			this.popForm.mode = _mode;
			this.popForm.itemData = _item;
			if (this.popForm.mode == "edit") {
				this.popForm.form = {
					id: _item.id,
					photo1: null,
					photo2: null,
					photo3: null,
					photo1_base64: null,
					photo2_base64: null,
					photo3_base64: null,
					name: _item.name,
					title: _item.title,
					content: _item.content,
					skill: '',
					experience: [{ name: '', order: '' }],
					credential: [{ name: '', order: '' }],
				}

				if (_item.lawyer_skill[0]) {
					this.popForm.form.skill = _item.lawyer_skill[0].name;
				}
				if (_item.lawyer_photo[0]) {
					this.popForm.form.photo1 = this.dataURLtoFile('data:image/jpeg;base64,' + _item.lawyer_photo[0].image, "已上傳檔案");
					toBase64(this.popForm.form.photo1).then(res => {
						this.popForm.form.photo1_base64 = res;
					}).catch(err => {
						console.log(err);
					})

				}
				if (_item.lawyer_photo[1]) {
					this.popForm.form.photo2 = this.dataURLtoFile('data:image/jpeg;base64,' + _item.lawyer_photo[1].image, "已上傳檔案");
					toBase64(this.popForm.form.photo1).then(res => {
						this.popForm.form.photo2_base64 = res;
					}).catch(err => {
						console.log(err);
					})
				}
				if (_item.lawyer_photo[2]) {
					this.popForm.form.photo3 = this.dataURLtoFile('data:image/jpeg;base64,' + _item.lawyer_photo[2].image, "已上傳檔案");
					toBase64(this.popForm.form.photo3).then(res => {
						this.popForm.form.photo3_base64 = res;
					}).catch(err => {
						console.log(err);
					})
				}
				if (_item.lawyer_experience.length != -1) this.popForm.form.experience = [];
				_item.lawyer_experience.forEach(element => {
					this.popForm.form.experience.push({ name: element.name, order: element.order });
				});
				if (_item.lawyer_credential.length != -1) this.popForm.form.credential = [];
				_item.lawyer_credential.forEach(element => {
					this.popForm.form.credential.push({ name: element.name, order: element.order });
				});
			}
		},
		popForm_save() {
			this.popForm.msg = "";
			var _pf = this.popForm.form;
			if (!_pf.photo1) {
				this.popForm.msg = "請上傳 上方形象照 1";
				return;
			}
			if (!_pf.photo2) {
				this.popForm.msg = "請上傳 上方形象照 2";
				return;
			}
			if (!_pf.photo3) {
				this.popForm.msg = "請上傳 下方形象照";
				return;
			}
			if (!_pf.title) {
				this.popForm.msg = "請上傳 子標題";
				return;
			}
			if (!_pf.content) {
				this.popForm.msg = "請上傳 子內文";
				return;
			}
			if (!_pf.skill) {
				this.popForm.msg = "請上傳 專長";
				return;
			}

			var needReturn = 0;
			/* experience */
			if (!_pf.experience) {
				this.popForm.msg = "經歷 不可為空";
				return;
			}
			var _experience = [];
			_pf.experience.forEach((element, index) => {
				_experience.push({
					"name": element.name,
					"order": index
				})
				if (element.name == '') {
					this.popForm.msg = "經歷 不可為空";
					needReturn = 1;
					return;
				}
			});
			/* credential */
			if (!_pf.credential) {
				this.popForm.msg = "證書 不可為空";
				return;
			}
			var _credential = [];
			_pf.credential.forEach((element, index) => {
				_credential.push({
					"name": element.name,
					"order": index
				})
				if (element.name == '') {
					this.popForm.msg = "證書 不可為空";
					needReturn = 1;
					return;
				}
			});
			if (needReturn) return;

			var payload = {
				"id": _pf.id,
				"name": _pf.name,
				"title": _pf.title,
				"content": _pf.content,
				"skill": [
					{
						"name": _pf.skill,
						"order": 0
					}
				],
				"experience": _experience,
				"credential": _credential,
				"photo": [
					{
						"image": this.popForm.form.photo1_base64, "order": 0
					},
					{
						"image": this.popForm.form.photo2_base64, "order": 0
					},
					{
						"image": this.popForm.form.photo3_base64, "order": 0
					}

				]
			};
			// return;
			if (this.popForm.mode == "edit") {
				this.GetUpdateLawyer(payload);
				return;
			}
		},
		popForm_clean() {
			this.popForm = {
				mode: "",
				show: false,
				itemData: {},
				formData: new FormData(),
				form: {
					ckeckAgain: null,
					tank: null,
					times: 1,
					measurement_type: null,
					date: null,
					image: null,
					banner: null,
				},
				msg: ""
			}
		},
		ChangePage(num, mode) {
			var payload = {};
			if (mode == 'search' || !this.tableModule.searchHistory) {
				payload = {
					"page": num,
					"count": 1,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0,
				}
				if (this.searchInfo.tank) payload.search.tank_id = [this.searchInfo.tank.id];
				if (this.searchInfo.area) payload.search.area_id = [this.searchInfo.area.id];
				if (this.searchInfo.org) payload.search.org_id = [this.searchInfo.org.id];
				if (this.searchInfo.measurement_type) payload.search.measurement_type_id = [this.searchInfo.measurement_type.id];
				if (this.searchInfo.dateStart) payload.search.date_from = this.searchInfo.dateStart.toISOString().slice(0, 10);
				if (this.searchInfo.dateEnd) payload.search.date_end = this.searchInfo.dateEnd.toISOString().slice(0, 10);
				this.tableModule.searchCache = payload;
			} else {
				this.tableModule.searchCache = this.tableModule.searchHistory;
				this.tableModule.searchCache.page = num;
				this.tableModule.searchCache.sort_field = this.tableModule.sort_field
				this.tableModule.searchCache.sort_order = this.tableModule.sort_order
			}
			this.tableModule.searchCache.is_output = mode == 'download' ? 1 : 0;
			console.log(this.tableModule.searchCache);
			this.GetSearchLawyer(this.tableModule.searchCache);
		},
		dataURLtoFile(dataurl, filename) {
			var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, { type: mime });
		},
		fileChange(e, key) {
			// this.popForm.formData = new FormData();
			// this.popForm.formData.append('image', e.target.files[0]); //放進上傳的檔案
			// this.popForm.formData_info = e.target.files[0];
			console.log(key)
			this.popForm.msg = null;
			this.popForm.form[key + '_base64'] = null;
			if ((e.target.files[0].size / 1000000 * 100 / 100) >= this.file_max) {
				this.popForm.form[key] = null;
				this.popForm.msg = '檔案超過100MB無法上傳';
				return
			}
			this.popForm.form[key] = e.target.files[0];
			toBase64(this.popForm.form[key]).then(res => {
				this.popForm.form[key + '_base64'] = res;
			}).catch(err => {
				console.log(err);
			})
		},
		cleanDate() {
			this.searchInfo.dateEnd = null;
			this.searchInfo.dateStart = null;
		},
		previewObjectURL(e, key) {
			var objectURL = window.URL.createObjectURL(this.popForm.form[key]);
			return objectURL;
		},
		addInputArr(key) {
			if (this.popForm.form[key].length >= 20) return;
			this.popForm.form[key].push({ name: '', order: this.popForm.form[key].length })
		},
		deleteInputArr(key, index) {
			if (this.popForm.form[key].length <= 1) return;
			this.popForm.form[key].splice(index, 1);
		}
	}
}
</script>