import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/baisc/Login.vue'
import Layout from '../../src/layouts/Layout.vue' 
import ArticleManagement from '../views/main/ArticleManagement.vue'
import OfferingManagement from '../views/main/OfferingManagement.vue'
import ContentManagement from '../views/main/ContentManagement.vue'
import KnowledgeManagement from '../views/main/KnowledgeManagement.vue'
import WebsiteSetting from '../views/main/WebsiteSetting.vue'
import CarouselManagement from  '../views/main/carouselManagement.vue'
import ConsultationForm from  '../views/main/ConsultationForm.vue'
import AboutManagement from  '../views/main/AboutManagement.vue'
import Home from '../views/main/Home.vue'
import store from '../store'
const routes = [
	{
		path: '', // 如果沒有填入路徑，元件Login會是一級路由的預設
		name: 'login',
		component: Login
	},
	{
		path: "/",
		name: "layout",
		component: Layout,
		children: [
			{
				path: "/home",
				name: "home", 
				component: Home,
				meta: { requiresAuth: true } // 需要驗證登入
			}, 
			{
				path: "/article-management",
				name: "ArticleManagement", 
				component: ArticleManagement,
				linkActiveClass: "active",
				meta: { requiresAuth: true } // 需要驗證登入
			}, 
			{
				path: "/knowledge-management",
				name: "KnowledgeManagement", 
				component: KnowledgeManagement,
				linkActiveClass: "active",
				meta: { requiresAuth: true } // 需要驗證登入
			}, 
			{
				path: "/about-management",
				name: "AboutManagement", 
				component: AboutManagement,
				linkActiveClass: "active",
				meta: { requiresAuth: true } // 需要驗證登入
			}, 
			{
				path:"/offering-management",
				name: "OfferingManagement", 
				component: OfferingManagement,
				linkActiveClass: "active",
				meta: { requiresAuth: true } // 需要驗證登入
			},
			{
				path: "/content-management",
				name: "contentManagement",
				component: ContentManagement, 
				linkActiveClass: "active",
				meta: { requiresAuth: true } // 需要驗證登入
			},
			{
				path: "/website-setting",
				name: "WebsiteSetting",
				component: WebsiteSetting, 
				linkActiveClass: "active",
				meta: { requiresAuth: true } // 需要驗證登入
			},
			{
				path: "/carousel-management",
				name: "CarouselManagement",
				component: CarouselManagement, 
				linkActiveClass: "active",
				meta: { requiresAuth: true } // 需要驗證登入
			},
			{
				path: "/consultation-form",
				name: "ConsultationForm",
				component: ConsultationForm, 
				linkActiveClass: "active",
				meta: { requiresAuth: true } // 需要驗證登入
			},
			// {
			// 	path: "/offering-management",
			// 	name: "offeringManagement",
			// 	component: offeringManagement, 
			// 	linkActiveClass: "active",
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// },

			
			// {
			// 	path: "/hcp-upload",
			// 	name: "hcp-upload",
			// 	component: HcpUpload,
			// 	linkActiveClass: "active",
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// },
			// {
			// 	path: "/account-management",
			// 	name: "account-management",
			// 	component: AccountManagement,
			// 	linkActiveClass: "active",
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// },
			// {
			// 	path: "/org-and-mr-query",
			// 	name: "org-and-mr-query",
			// 	component: OrganizationMR,
			// 	linkActiveClass: "active",
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// },
			// {
			// 	path: "/new-hcp-review",
			// 	name: "/new-hcp-review",
			// 	component: NewHcpReview,
			// 	linkActiveClass: "active",
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// },
			// {
			// 	path: "/content-management",
			// 	name: "/content-management",
			// 	component: ContentManagement,
			// 	linkActiveClass: "active",
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// },
			// {
			// 	path: "/kpi-report",
			// 	name: "/kpi-Report",
			// 	component: KpiReport,
			// 	linkActiveClass: "active",
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// },
			// {
			// 	path: "/url/04",
			// 	name: "page04", 
			// 	linkActiveClass: "active",
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// }
		]
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

// 假設有一個方法用於驗證TOKEN的有效性，可以根據您的需求進行修改
function isTokenValid() {
	// 在此處添加您的驗證TOKEN的邏輯，例如檢查TOKEN是否有效
	// 返回 true 或 false，表示TOKEN是否有效
	var userInfo = JSON.parse(localStorage.getItem('userInfo'));
	if (!userInfo) return false;
	store.state.userInfo = userInfo; 
	var token = userInfo.token || store.state.token;
	if (userInfo.token) store.commit('SetLoginToken',userInfo.token);

	if (token)
		return true;
	else
		return false;
}

router.beforeEach((to, from, next) => {
	if (to.matched.some(route => route.meta.requiresAuth)) {
		// 需要驗證TOKEN
		if (isTokenValid()) {
			// TOKEN有效，允許訪問該頁面 
			next();
		} else {
			// TOKEN無效，導向登入頁面 
			next('/');
		}
	} else {
		// 不需要驗證TOKEN，允許訪問該頁面
		next();
	}
})
export default router
