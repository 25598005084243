<template>
	<body>
		<header class="navbar   sticky-top flex-md-nowrap p-0 shadow">
			<a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="/home">
				<img src="../assets/head-logo.svg">
			</a>
			<button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse"
				data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"
				aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div class="navbar-nav">
				<div class="nav-item text-nowrap">
					<a class="nav-link px-3 nav-user">
						<!-- <img src="../assets/img/user.png" class="login-icon-user">  -->
						<font-awesome-icon :icon="['fas', 'user']" size="sm" style="margin-right: 10px;" />
						{{ $store.state.userInfo.name }}
					</a>
				</div>
				<div class="nav-item text-nowrap nav-dropdown">
					<div class="flex-shrink-0 dropdown">
						<a href="#" class="d-block link-dark text-decoration-none  " id="dropdownUser2"
							data-bs-toggle="dropdown" aria-expanded="false">
							⋮
						</a>
						<ul class="dropdown-menu dropdown-menu-user  text-small shadow  " aria-labelledby="dropdownUser2"
							data-popper-placement="bottom-end"
							style="position: absolute; inset: 0px auto auto 0px; margin: 0px;transform: translate3d(calc(-100% + 20px), 40px, 0px);">
							<li>
								<a class="dropdown-item" @click="changePsw.show = true">
									<img src="../assets/img/changePsw.png" class="login-icon-changePsw">
									Change Password
								</a>
							</li>
							<li>
								<a class="dropdown-item" @click="Logout()">
									<img src="../assets/img/logout.png" class="login-icon-logout">
									Log Out
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</header>

		<div class="container-fluid">
			<div class="row">
				<nav id="sidebarMenu" class="  d-md-block bg-light sidebar collapse" style="">
					<div class="position-sticky pt-4">
						<ul class="nav flex-column">
							<li class="nav-item" v-for="(_list, index) in $store.state.sideBar" :key="index">
								<!-- active -->
								<router-link v-bind:to="_list.url" class="nav-link ">
									<svg v-if="!_list.icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
										viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
										stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"
										aria-hidden="true">
										<path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
										<polyline points="13 2 13 9 20 9"></polyline>
									</svg>
									<img class="nav-icon" v-if="_list.icon"
										v-bind:src="'data:image/jpeg;base64,' + _list.icon">
									{{ _list.name }}
								</router-link>
							</li>
						</ul>
					</div>
				</nav>
				<main class="layoutMain p-0">
					<router-view></router-view>
				</main>
			</div>
		</div>

		<div class="changePswPanel" v-if="changePsw.show">
			<div class="cpp_container">
				<div class="cpp_title">Change Password</div>
				<span class=" cpp_xmark" @click="changePsw_cancel()">
					✕
				</span>
				<!-- {{ ChangePswInfo }} -->
				<form>
					<div class="input-group  input-group-psw">
						<span class="input-group-text igt-before" id="">
							<img src="../assets/img/psw.png" class="login-icon-psw">
							<img src="../assets/img/show.png" class="login-icon-show" :class="{ 'hide': showPsw }"
								@click="showPsw = true">
							<img src="../assets/img/unshow.png" class="login-icon-unshow" :class="{ 'hide': !showPsw }"
								@click="showPsw = false">
						</span>
						<input v-model="changePsw.ori_psw" v-bind:type="type_showPsw()" class="form-control"
							placeholder="Original password">
					</div>

					<div class="input-group  input-group-psw">
						<span class="input-group-text igt-before" id="">
							<img src="../assets/img/psw.png" class="login-icon-psw">
							<img src="../assets/img/show.png" class="login-icon-show" :class="{ 'hide': showPsw }"
								@click="showPsw = true">
							<img src="../assets/img/unshow.png" class="login-icon-unshow" :class="{ 'hide': !showPsw }"
								@click="showPsw = false">
						</span>
						<input v-model="changePsw.con_psw_1" v-bind:type="type_showPsw()" class="form-control"
							placeholder="Confirm password">
					</div>

					<div class="input-group input-group-psw">
						<span class="input-group-text igt-before" id="">
							<img src="../assets/img/psw.png" class="login-icon-psw">
							<img src="../assets/img/show.png" class="login-icon-show" :class="{ 'hide': showPsw }"
								@click="showPsw = true">
							<img src="../assets/img/unshow.png" class="login-icon-unshow" :class="{ 'hide': !showPsw }"
								@click="showPsw = false">
						</span>
						<input v-model="changePsw.con_psw_2" v-bind:type="type_showPsw()" class="form-control"
							placeholder="Confirm password">
					</div>
				</form>
				<div class="errMsg">{{ changePsw.msg }}</div>
				<div class="errMsg px-3 pb-1 text-start" :class="{ 'errMsg_blue': changePsw.pswformatSucc }">
					<div class="pswformatSucc_icon"></div>包含英文大小寫+數字
				</div>
				<div class="errMsg px-3 text-start" :class="{ 'errMsg_blue': changePsw.pswformatSucc }">
					<div class="pswformatSucc_icon"></div>至少八位數
				</div>
				<div class="cpp_btn">
					<button class="btn btn-outline-primary" @click="changePsw_cancel()">Cancel</button>
					<button class="btn btn-primary" @click="ChangePsw()">Save</button>
				</div>
			</div>
		</div>
		<div class="loaderPanel" v-if="$store.state.loading">
			<span class="loader"></span>
		</div>
		<div class="alertPanel"
			v-bind:class="{ 'success': $store.state.alert.mode == 'success', 'alert': $store.state.alert.mode == 'alert' }"
			v-if="$store.state.alert.show">
			<span>{{ $store.state.alert.msg }}</span>
		</div>
	</body>
</template>

<script>
// @ is an alias to /src  

import { mapState } from 'vuex'
export default {
	name: 'Layout',
	computed: {
		...mapState(
			{
				ChangePswInfo: state => state.ChangePswInfo,
			}
		),
	},
	watch: {
		ChangePswInfo(val) {
			if (val && val.data.header.message == "Success") {
				this.changePsw = {
					show: false,
					ori_psw: "",
					con_psw_1: "",
					con_psw_2: "",
				}
			} else {
				this.changePsw.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.changePsw.msg = val.data.data[i][0];
				}
			}

		},
		'changePsw.con_psw_1'(val) {
			let regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);
			if (this.changePsw.con_psw_1 == this.changePsw.con_psw_2 && regex.test(val)) this.changePsw.pswformatSucc = true;
			else this.changePsw.pswformatSucc = false;
		},
		'changePsw.con_psw_2'(val) {
			let regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);
			if (this.changePsw.con_psw_1 == this.changePsw.con_psw_2 && regex.test(val)) this.changePsw.pswformatSucc = true;
			else this.changePsw.pswformatSucc = false;
		}
	},
	data() {
		return {
			showPsw: false,
			changePsw: {
				show: false,
				ori_psw: "",
				con_psw_1: "",
				con_psw_2: "",
				msg: "",
				pswformatSucc: false
			},
			img_url: '',
		}
	},
	methods: {
		Logout() {
			this.$store.dispatch('Logout');
		},
		ChangePsw() {
			this.changePsw.msg = "";
			if (this.changePsw.con_psw_1 != this.changePsw.con_psw_2) {
				this.changePsw.msg = "新密碼兩者需相同";
				return;
			}
			if (!this.changePsw.con_psw_2 && !this.changePsw.ori_psw) {
				this.changePsw.msg = "欄位不可為空";
				return;
			}
			if (this.changePsw.con_psw_2 == this.changePsw.ori_psw) {
				this.changePsw.msg = "新舊密碼不可相同";
				return;
			}
			let regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)
			if (!regex.test(this.changePsw.con_psw_1)) {
				this.changePsw.msg = "";
				return;
			}
			this.$store.dispatch('ChangePsw', {
				"original_password": this.changePsw.ori_psw,
				"password": this.changePsw.con_psw_2
			}
			);
		},
		type_showPsw() {
			return this.showPsw ? 'text' : 'password';
		},
		changePsw_cancel() {
			this.changePsw = {
				show: false,
				ori_psw: "",
				con_psw_1: "",
				con_psw_2: "",
			}
		},
	},
}
</script> 

<style src="vue-multiselect/dist/vue-multiselect.css"></style>