<template>
	<div class="container-fluid">
		<div class="row p-4 py-0 my-5" v-if="searchWebsiteSettingList && searchWebsiteSettingList.list">
			<!-- <div v-if="searchWebsiteSettingList && searchWebsiteSettingList.list">
				{{ searchWebsiteSettingList.list[0]
				}}
				{{ this.popForm.itemData }}
			</div> -->
			<div class="col-3 mb-3">
				<label class="form-lable require">首頁的形象照</label>
			</div>
			<div class="col-9 mb-3">
				<button class="addFile-btn" onclick="document.getElementById('getFile1').click()">
					<font-awesome-icon :icon="['fas', 'plus']" class="me-2" />
					選擇檔案
				</button>
				<div style="visibility: hidden;height: 0;">
					<input type="file" id="getFile1" accept="image/*" @change="fileChange($event, 'image')"
						:key="popForm.form.image">
				</div>
				<div class=" mt-3 fileInfoGroup">
					<span v-if="popForm.form.image" class="file-info">
						<img src="../../assets/img/file.png"> {{ popForm.form.image.name }} (
						{{ Math.round(popForm.form.image.size / 1000000 * 100) / 100 }}MB)
					</span>
					<img v-if="popForm.form.image" :src="previewObjectURL($event, 'image')" class="filePreview" alt="">
					<div class="errMsg"
						v-if="popForm.form.image && popForm.form.image.size / 1000000 * 100 / 100 > file_max">
						檔案超過2MB無法上傳
					</div>
				</div>
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">banner圖片</label>
			</div>
			<div class="col-9 mb-3">
				<button class="addFile-btn" onclick="document.getElementById('getFile2').click()">
					<font-awesome-icon :icon="['fas', 'plus']" class="me-2" />
					選擇檔案
				</button>
				<div style="visibility: hidden;height: 0;">
					<input type="file" id="getFile2" accept="image/*" @change="fileChange($event, 'banner')"
						:key="popForm.form.banner">
				</div>
				<div class=" mt-3 fileInfoGroup">
					<span v-if="popForm.form.banner" class="file-info">
						<img src="../../assets/img/file.png"> {{ popForm.form.banner.name }} (
						{{ Math.round(popForm.form.banner.size / 1000000 * 100) / 100 }}MB)
					</span>
					<img v-if="popForm.form.banner" :src="previewObjectURL($event, 'banner')" class="filePreview"
						alt="">
					<div class="errMsg"
						v-if="popForm.form.banner && popForm.form.banner.size / 1000000 * 100 / 100 > file_max">
						檔案超過2MB無法上傳
					</div>
				</div>
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">首頁的關於</label>
			</div>
			<div class="col-9 mb-3">
				<textarea v-model="popForm.form.about" class="form-control" placeholder="Please enter"
					rows="3"></textarea>
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">地址</label>
			</div>
			<div class="col-9 mb-3">
				<input v-model="popForm.form.address" class="form-control" placeholder="Please enter" />
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">手機</label>
			</div>
			<div class="col-9 mb-3">
				<input v-model="popForm.form.mobile" class="form-control" placeholder="Please enter" />
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">信箱</label>
			</div>
			<div class="col-9 mb-3">
				<input v-model="popForm.form.email" class="form-control" placeholder="Please enter" />
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">line ID</label>
			</div>
			<div class="col-9 mb-3">
				<input v-model="popForm.form.line" class="form-control" placeholder="Please enter" />
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">fb網址</label>
			</div>
			<div class="col-9 mb-3">
				<input v-model="popForm.form.facebook" class="form-control" placeholder="Please enter" />
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">諮詢須知內文</label>
			</div>
			<div class="col-9 mb-3">
				<textarea v-model="popForm.form.consultation_form_content" class="form-control"
					placeholder="Please enter" rows="3"></textarea>
			</div>
			<div class="col-3 mb-3">
				<label class="form-lable require">預約諮詢內文</label>

			</div>
			<div class="col-9 mb-3">
				<textarea v-model="popForm.form.consultation_form_info" class="form-control" placeholder="Please enter"
					rows="3"></textarea>
			</div>

			<div class="errMsg">{{ popForm.msg }}</div>
			<div class="cpp_btn">
				<button class="btn btn-outline-primary"
					@click="popForm_show('edit', searchWebsiteSettingList.list[0])">Cancel</button>
				<button class="btn btn-primary " @click="popForm_save">Save</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
	searchUserList: {},
	name: 'KnowledgeManagement',
	components: {
	},
	computed: {
		...mapState(
			{
				searchWebsiteSettingList: state => state.websiteSetting.search,
				updateWebsiteSetting: state => state.websiteSetting.update,
			}
		),
	},
	data() {
		const format = (date) => {
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			return `${year}/${month}/${day}`;
		}
		return {
			format,
			file_max: 2,
			tableModule: {
				searchHistory: null,
				searchCache: null,
				sort_order: "desc",
				sort_field: "id"
			},
			searchInfo: {
				name: null,
				checkedType: [],
				dateStart: null,
				dateEnd: null,
			},
			areaId: "",
			cm_pageInfo: {
				totalPage: 0,
				nowPage: 0,
			},
			popForm: {
				mode: "",
				show: false,
				itemData: {},
				formData: new FormData(),
				// formData_info: null,
				form: {
					ckeckAgain: null,
					tank: null,
					times: 1,
					measurement_type: null,
					date: null,
					image: null,
					banner: null,
				},
				msg: ""
			}
		};
	},
	mounted() {
		this.ChangePage(1)

	},
	watch: {
		searchWebsiteSettingList: function (val) {
			this.cm_pageInfo = {
				totalPage: val.total_page,
				nowPage: val.page,
			}
			this.tableModule.searchHistory = this.tableModule.searchCache;
			if (val && val.list)
				this.popForm_show('edit', val.list[0])
		},
		updateWebsiteSetting: function (val) {
			if (val.data.header.message == "Success") {
				this.GetSearchWebsiteSetting({
					"page": 1,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0,
					"search": {
						"id": [1]
					}
				})
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}

		}
	},
	methods: {
		...mapActions([
			'GetSearchWebsiteSetting',
			'GetUpdateWebsiteSetting'
		]),
		popForm_show(_mode, _item) {
			this.popForm.show = true;
			this.popForm.mode = _mode;
			this.popForm.itemData = _item;
			if (this.popForm.mode == "edit") {
				this.popForm.form = {
					id: _item.id,
					image: this.dataURLtoFile('data:image/jpeg;base64,' + _item.image, "已上傳檔案"),
					banner: this.dataURLtoFile('data:image/jpeg;base64,' + _item.banner, "已上傳檔案"),
					about: _item.about,
					address: _item.address,
					mobile: _item.mobile,
					email: _item.email,
					line: _item.line,
					facebook: _item.facebook,
					consultation_form_content: _item.consultation_form_content,
					consultation_form_info: _item.consultation_form_info,
				}
			}
		},
		popForm_save() {
			this.popForm.msg = "";
			var _pf = this.popForm.form;

			if (!_pf.image) {
				this.popForm.msg = "請上傳 首頁的形象照";
				return;
			}
			if (!_pf.banner) {
				this.popForm.msg = "請上傳 banner圖片";
				return;
			}
			if (!_pf.about) {
				this.popForm.msg = "首頁的關於 為必填";
				return;
			}
			if (!_pf.address) {
				this.popForm.msg = "地址 為必填";
				return;
			}
			if (!_pf.mobile) {
				this.popForm.msg = "手機 為必填";
				return;
			}
			if (!_pf.email) {
				this.popForm.msg = "信箱 為必填";
				return;
			}
			if (!_pf.line) {
				this.popForm.msg = "line ID 為必填";
				return;
			}
			if (!_pf.facebook) {
				this.popForm.msg = "fb網址 為必填";
				return;
			}
			if (!_pf.consultation_form_content) {
				this.popForm.msg = "預約諮詢內文 為必填";
				return;
			}
			if (!_pf.consultation_form_info) {
				this.popForm.msg = "預約諮詢內文 為必填";
				return;
			}
			this.popForm.formData = new FormData();
			this.popForm.formData.append("image", _pf.image);
			this.popForm.formData.append("banner", _pf.banner);
			this.popForm.formData.append("about", _pf.about);
			this.popForm.formData.append("address", _pf.address);
			this.popForm.formData.append("mobile", _pf.mobile);
			this.popForm.formData.append("email", _pf.email);
			this.popForm.formData.append("line", _pf.line);
			this.popForm.formData.append("facebook", _pf.facebook);
			this.popForm.formData.append("consultation_form_content", _pf.consultation_form_content);
			this.popForm.formData.append("consultation_form_info", _pf.consultation_form_info);

			if (this.popForm.mode == "edit") {
				this.popForm.formData.append("id", this.popForm.form.id)
				this.GetUpdateWebsiteSetting(this.popForm.formData);
				return;
			}
		},
		popForm_clean() {
			this.popForm = {
				mode: "",
				show: false,
				itemData: {},
				formData: new FormData(),
				form: {
					ckeckAgain: null,
					tank: null,
					times: 1,
					measurement_type: null,
					date: null,
					image: null,
					banner: null,
				},
				msg: ""
			}
		},
		ChangePage(num, mode) {
			var payload = {};
			if (mode == 'search' || !this.tableModule.searchHistory) {
				payload = {
					"page": num,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0,
					"search": {
						"id": [1]
					}
				}
				if (this.searchInfo.tank) payload.search.tank_id = [this.searchInfo.tank.id];
				if (this.searchInfo.area) payload.search.area_id = [this.searchInfo.area.id];
				if (this.searchInfo.org) payload.search.org_id = [this.searchInfo.org.id];
				if (this.searchInfo.measurement_type) payload.search.measurement_type_id = [this.searchInfo.measurement_type.id];
				if (this.searchInfo.dateStart) payload.search.date_from = this.searchInfo.dateStart.toISOString().slice(0, 10);
				if (this.searchInfo.dateEnd) payload.search.date_end = this.searchInfo.dateEnd.toISOString().slice(0, 10);
				this.tableModule.searchCache = payload;
			} else {
				this.tableModule.searchCache = this.tableModule.searchHistory;
				this.tableModule.searchCache.page = num;
				this.tableModule.searchCache.sort_field = this.tableModule.sort_field
				this.tableModule.searchCache.sort_order = this.tableModule.sort_order
			}
			this.tableModule.searchCache.is_output = mode == 'download' ? 1 : 0;
			console.log(this.tableModule.searchCache);
			this.GetSearchWebsiteSetting(this.tableModule.searchCache);
		},
		dataURLtoFile(dataurl, filename) {
			var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, { type: mime });
		},
		fileChange(e, key) {
			// this.popForm.formData = new FormData();
			// this.popForm.formData.append('image', e.target.files[0]); //放進上傳的檔案
			// this.popForm.formData_info = e.target.files[0];
			this.popForm.msg = null;
			if ((e.target.files[0].size / 1000000 * 100 / 100) >= this.file_max) {
				this.popForm.form[key] = null;
				this.popForm.msg = '檔案超過100MB無法上傳';
				return
			}
			this.popForm.form[key] = e.target.files[0];
		},
		cleanDate() {
			this.searchInfo.dateEnd = null;
			this.searchInfo.dateStart = null;
		},
		previewObjectURL(e, key) {
			var objectURL = window.URL.createObjectURL(this.popForm.form[key]);
			return objectURL;
		}
	}
}
</script>